.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.layout5 #head {
  margin-top: calc(var(--spaceTotal) * 2);
}
@media (max-width: 1023px) {
  #navilinks {
    padding-left: 19.15584416%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
h1 {
  font-size: 65px;
  line-height: 1;
}
.area--one {
  width: 103.1779661%;
  margin-left: -1.58898305%;
  margin-top: calc(var(--spaceTotal) * 1);
  margin-bottom: calc(var(--spaceTotal) * 1);
}
.layout4 .area--one.area1 {
  margin-top: calc(var(--spaceTotal) * 2);
}
.area--two {
  margin-top: calc(var(--spaceTotal) * 1);
  margin-bottom: calc(var(--spaceTotal) * 1);
}
.area--intro {
  padding-left: 40.6779661%;
  box-sizing: border-box;
  margin-top: calc(var(--spaceTotal) * 1);
  margin-bottom: calc(var(--spaceTotal) * 1);
}
.area--one .unit {
  width: 96.91991786%;
  margin-left: 1.54004107%;
  margin-right: 1.54004107%;
}
.area--one .unit.unit--slim {
  width: 46.91991786%;
}
.area--one .unit.unit--sideimage {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.area--one .unit.unit--sideimage-right {
  flex-direction: row-reverse;
}
.area--one .unit.unit--sideimage .unit__background {
  width: 48.41101695%;
}
.area--one .unit.unit--sideimage .unit__content {
  width: 48.41101695%;
}
.area--one .unit.unit--simple .part.tiny {
  padding: 0 16.11909651%;
  box-sizing: border-box;
}
.area--masonry .unit {
  width: 46.91991786%;
  margin-left: 1.54004107%;
  margin-right: 1.54004107%;
  box-sizing: border-box;
}
.area--masonry .unit:nth-child(4n+1) {
  padding-right: 9.85626283%;
}
.area--masonry .unit:nth-child(4n+3) {
  padding-left: 9.85626283%;
}
/*# sourceMappingURL=./screen-medium.css.map */